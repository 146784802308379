import MSaga from "../../helper/MSaga";
import { userActions } from "./userReducer";
import appUrl from "../../config/appUrl";
import { call, put } from "redux-saga/effects";
import { showNotification } from "../../utils/commonFunctions";
import axios from "axios";

const createOneApi = (data) => {
  return axios({
    method: "POST",
    url: `${appUrl.USERS}/invite-user`,
    data: data,
  });
};

function* handleCreateOne(action) {
  try {
    const mFilter = action?.payload?.filter;
    delete action?.payload?.filter;

    const response = yield call(createOneApi, action.payload);
    const { data } = response;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(userActions.onCreateOneFailure(data));
    } else {
      data.filter = mFilter || JSON.stringify({});
      data.newCreatedDataId = data.data._id;
      yield put(userActions.onCreateOneSuccess(data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("MSaga Create One", error);
    yield put(userActions.onCreateOneFailure(error));
  }
}

const userSaga = new MSaga({
  name: "user",
  apiEndPoint: appUrl.USERS,
  actions: userActions,
  workers: { handleCreateOne },
});

export default userSaga.getWatchSaga();
