import { all } from "redux-saga/effects";

import loggedInUserSaga from "../loggedInUser/duck/loggedInUserSaga";
import userSage from "../user/duck/userSage";
import roleSage from "../role/duck/roleSage";
import commentSage from "../comment/duck/commentSage";
import taskSage from "../task/duck/taskSage";
import projectSaga from "../project/duck/projectSaga";
import itemSage from "../item/duck/itemSage";
import templateSaga from "../template/duck/templateSaga";
import dashboardSaga from "../dashboard/duck/dashboardSaga";

function* rootSaga() {
  yield all([
    loggedInUserSaga(),
    userSage(),
    roleSage(),
    taskSage(),
    commentSage(),
    projectSaga(),
    itemSage(),
    dashboardSaga(),
    templateSaga(),
  ]);
}

export default rootSaga;
