import { Button, Col, Row } from "antd";
import React, { useCallback, useMemo } from "react";
import logo from "../assets/images/logoBlack.svg";
import ToDoList from "../assets/images/TodoList.svg";
import "./Home.scss";

const Home = () => {
  const redirectToForm = useCallback(() => {
    window.open(
      "https://app.procezo.com/form/63908ecba749490a9867bf15",
      "_blank"
    );
  }, []);

  return (
    <div className="home-page">
      <Row align="middle" justify="space-between">
        <img src={logo} alt="Writely" className="logo pointer" />
        <div className="nav-items">
          <a
            href={"https://app.writely.io/creator"}
            className="item"
            style={{
              color: "rgba(0,0,0,.85)",
              backgroundColor: "#fff",
              fontVariant: "tabular-nums",
            }}
          >
            Creator (Writer)
          </a>
          <a
            className="item"
            style={{
              color: "rgba(0,0,0,.85)",
              backgroundColor: "#fff",
              fontVariant: "tabular-nums",
            }}
            href={"https://app.writely.io/requestor"}
          >
            Requestor (Client | Business)
          </a>
          <a
            className="item"
            style={{
              color: "rgba(0,0,0,.85)",
              backgroundColor: "#fff",
              fontVariant: "tabular-nums",
            }}
            href={"https://app.writely.io/login"}
          >
            Login
          </a>
          <Button
            onClick={redirectToForm}
            size="large"
            className="contact-btn mb20"
          >
            Contact Us
          </Button>
        </div>
      </Row>
      <Row className="mt10" justify="space-between">
        <Col lg={12} md={24} sm={24} xs={24}>
          <img src={ToDoList} alt="" />
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div className="page-text-container">
            <p>World’s First Content Marketplace by Experts</p>
            <span className="plain-text">
              Create high quality content quickly and more efficiently with a
              robust content marketing stack.
            </span>
            <Button
              type="primary"
              onClick={redirectToForm}
              size="large"
              className="mt20"
            >
              Book a Demo
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
