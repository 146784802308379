import { Avatar, notification, Tooltip } from "antd";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";

import constants from "./constants";
import colors from "./colors";

const colorMap = {};
let lastColorIndex = 0;

export function getAvatarColor(id = "", transparency) {
  if (!id) {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  if (!colorMap.hasOwnProperty(id)) {
    colorMap[id] = colors[lastColorIndex];
    lastColorIndex = lastColorIndex + 1;
    lastColorIndex = lastColorIndex % colors.length;
  }
  return transparency ? `${colorMap[id]}${transparency}` : colorMap[id];
}

const tagColorMap = {};
let tagLastColorIndex = 0;

export function getTagColor(id = "", transparency) {
  const colors = ["purple", "geekblue", "green", "cyan", "lime"];

  if (!id) {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  if (!tagColorMap.hasOwnProperty(id)) {
    tagColorMap[id] = colors[tagLastColorIndex];
    tagLastColorIndex = tagLastColorIndex + 1;
    tagLastColorIndex = tagLastColorIndex % colors.length;
  }

  return tagColorMap[id];
}

export const validEmail = (email) => {
  let mailformat = /^\w+([\.-]?\w+[\+]?)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return mailformat.test(email);
};

export const validPassword = (password = "") => {
  return password.length >= 4;
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") return false;

  const token = localStorage.getItem(constants.ACCESS_TOKEN);
  if (token) {
    const user = jwtDecode(token);
    const dateNow = new Date();
    if (user?.exp > dateNow.getTime() / 1000) {
      return true;
    } else {
      localStorage.removeItem(constants.ACCESS_TOKEN);
    }
  }

  return false;
};

export const getIsSidebarCollapsedFromStore = () => {
  const isCollapsed = localStorage.getItem(constants.IS_SIDEBAR_COLLAPSED);
  return isCollapsed;
};

export const setIsSidebarCollapsedFromStore = (isCollapsed = false) => {
  if (isCollapsed) {
    localStorage.setItem(constants.IS_SIDEBAR_COLLAPSED, isCollapsed);
  } else {
    localStorage.removeItem(constants.IS_SIDEBAR_COLLAPSED);
  }
};

export const getNameInitials = (name = "") => {
  name = name?.trim?.();
  if (!name) return;

  let initials = name[0] || "";
  const index = name.indexOf(" ");
  if (index < name.length && index > 1) {
    initials += name[index + 1];
  }

  return initials.toUpperCase();
};

export const specialCharactersCheck = (name) => {
  let nameformat = /^[A-Za-z ]+$/;
  return nameformat.test(name);
};

export const isValidMobileNumber = (mobile = "") => {
  let format = /^[789]\d{9}$/;
  let countryCode = "+91";

  if (countryCode === "+91") format = /^[6789]\d{9}$/;

  if (!countryCode || !format) return false;

  return format.test(mobile);
};

export const isValidLinkdinURL = (string) => {
  let res = string.match(/^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/gim);
  return res !== null;
};

export const isValidWebsiteURL = (string) => {
  let res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const showNotification = (type, msg) => {
  notification[type]({
    message: msg,
    placement: "bottomLeft",
  });
};

export const paramsToString = (params = {}, removeKeys = []) => {
  const mParams = { ...params };
  delete mParams?.offset;
  delete mParams?.limit;
  removeKeys.forEach((key) => delete mParams?.[key]);

  return JSON.stringify(mParams);
};

export const getStringToOptions = (string = "") => string?.split?.("|") || [];

export const getOptionsToString = (options = []) => options.join("|");

export const disabledDate = (current) => {
  // Can not select days before today
  return current && current < moment().startOf("day");
};

export const guidGenerator = () => {
  let timestamp = parseInt(moment.utc().valueOf());
  return timestamp + Math.floor(Math.random() * 1000 + 1);
};

export const textWrapper = (text = "", maxLength = 15, returnLength = 14) => {
  if (typeof text === "string" && text?.length > maxLength) {
    return `${text.slice(0, returnLength)}...`;
  } else {
    return text;
  }
};
