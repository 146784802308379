import MSaga from "../../helper/MSaga";
import { itemActions } from "./itemReducer";
import appUrl from "../../config/appUrl";

const itemSaga = new MSaga({
  name: "item",
  apiEndPoint: appUrl.ITEMS,
  actions: itemActions,
});

export default itemSaga.getWatchSaga();
