import { combineReducers } from "redux";

import loggedInUserReducer from "../loggedInUser/duck/loggedInUserReducer";
import userReducer from "../user/duck/userReducer";
import roleReducer from "../role/duck/roleReducer";
import taskReducer from "../task/duck/taskReducer";
import commentReducer from "../comment/duck/commentReducer";
import projectReducer from "../project/duck/projectReducer";
import itemReducer from "../item/duck/itemReducer";
import dashboardReducer from "../dashboard/duck/dashboardReducer";
import templateReducer from "../template/duck/templateReducer";

import constants from "../utils/constants";
const { ACCESS_TOKEN, ACTION_TYPES } = constants;

const appReducer = combineReducers({
  loggedInUser: loggedInUserReducer,
  user: userReducer,
  dashboard:dashboardReducer,
  role: roleReducer,
  task: taskReducer,
  comment: commentReducer,
  project: projectReducer,
  item: itemReducer,
  template: templateReducer,
});

const rootReducer = (state, action) => {
  // clearing redux state when user logs out
  if (action.type === ACTION_TYPES.LOGOUT) {
    localStorage.removeItem(ACCESS_TOKEN);
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
