const routes = {
  ROOT: "/",

  // Authentication routes start
  // LOGIN: "/login",
  // FREELANCER_LOGIN: "/creator",
  // FREELANCER_SIGNUP: "/signup/creator",
  // // Authentication routes end
  // CLIENT_LOGIN: "/requestor",
  // CLIENT_SIGNUP: "/signup/requestor",
  //
  // //Signup routes start
  // SIGNUP: "/signup",
  // // Signup routes end
  //
  // //Signup routes start
  // FORGOTPASSWORD: "/forgotpassword",
  // // Signup routes end
  //
  // //Email-verify routes start
  // EMAIL_VERIFY: "/account-activate",
  // // Email-verify routes end
  //
  // //Password reset routes start
  // RESET_PASSWORD: "/reset-password",
  // //Password reset routes end
  //
  // //Password reset routes start
  // ADDITIONAL_DETAILS: "/basicdetails",
  // //Password reset routes end
  //
  // REGISTER_FREELANCER: "/register-freelancer",
  //
  // // app routes start
  // APP: "/app",
  //
  // // dashboard routes start
  // DASHBOARD: "/app/dashboard",
  // // dashboard routes end
  //
  // // task routes start
  //
  // TASK: "/app/task",
  // TASK_ID: "/app/task/:id",
  //
  // PROJECT_TASK: "/app/project-task",
  //
  // USERS: "/app/users",
  //
  // // task routes end
  // DATABASE: "/app/database",
  // FREELANCER: "/app/freelancers",
  // FREELANCER_ID: "/app/freelancers/:id",
  // // projects routes start
  //
  // // project list route
  // PROJECT: "/app/project",
  //
  // // project details route
  // PROJECT_DETAIL: "/app/project/:id",
  //
  // // create project route
  // CREATE_PROJECT_FLOW: "/app/create-project-flow",
  //
  // // project draft list route
  // PROJECT_DRAFT: "/app/project-draft",
  // // project drafts routes end
  //
  // // project archived list route
  // PROJECT_ARCHIVED: "/app/project-archived",
  // // project archived routes end
  //
  // // project task/assignment details route
  // PROJECT_TASK_DETAIL: "/app/project/:projectId/task/:id",
  //
  // // projects routes end
  //
  // // user profile routes start
  // PROFILE: "/app/profile",
  // // user profile routes end
  //
  // // role management routes
  // ROLES: "/app/roles",
  // ROLE_DETAILS: "/app/role/:id",
  //
  // // app routes end
  //
  // // user profile routes start
  // LOGOUT: "/logout",
  // // user profile routes end
  //
  // // form builder
  // FORM_BUILDER: "/app/form-builder",
  // // form builder end
  //
  // // template
  // TEMPLATE: "/app/template",
  // TEMPLATE_DETAIL: "/app/template/:id",
  // template end
};

Object.freeze(routes);
export default routes;
