import MSaga from "../../helper/MSaga";
import { roleActions } from "./roleReducer";
import appUrl from "../../config/appUrl";

const roleSaga = new MSaga({
  name: "role",
  apiEndPoint: appUrl.ROLES,
  actions: roleActions,
});

export default roleSaga.getWatchSaga();
