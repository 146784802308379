import MSlice from "../../helper/MSlice";

const handleLoading = (state) => {
  state.error = null;
  state.loading = true;
};

const handleArchiveRequestSuccess = (state, action) => {
  const { data, filter } = action.payload;

  const mBoard = state.boards?.[filter] || {};
  const list = mBoard?.list?.filter((id) => id !== data?._id);
  state.boards[filter] = Object.assign({}, mBoard, {
    list,
    meta: Object.assign({}, mBoard?.meta, {
      totalCount: mBoard?.meta?.totalCount - 1,
    }),
  });

  state.loading = false;
};

const handleError = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const projectSlice = new MSlice({
  name: "project",
  reducers: {
    onUpdateSectionFieldsRequest: MSlice.handleLoading,
    onShareWithRequest: MSlice.handleLoading,
    archiveRequest: handleLoading,
    onArchiveRequestSuccess: handleArchiveRequestSuccess,
    onArchiveRequestFailure: handleError,
  },
});

export const projectActions = projectSlice.getActions();

export default projectSlice.getReducer();
