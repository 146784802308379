import moment from "moment-timezone";
import { getStringToOptions } from "../utils/commonFunctions";
import Attachment from "./Attachment";

const KEYS = Object.freeze({
  ID: "_id",
  EMAIL: "email",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  CONTACT_NUMBER: "contact_number",
  ADDRESS: "address",
  DOMAIN: "domain",
  INDUSTRY: "industry",
  SUB_INDUSTRY: "sub_industry",
  RESOURCE_COST_PER_WORD: "resource_cost_per_word",
  GST_VAT: "gst_vat",
  RESUME_URL: "resumeUrl",
  SAMPLE_WORK: "sample_work",
  LINKEDIN_URL: "linkedin_URL",
  WEBSITE: "website",
  ADDITIONAL_DETAILS: "additional_details",
  THUMBNAIL: "thumbnail",
  TYPE: "type",
  ACTIVE: "active",
  VERIFIED: "verified",
  STATUS: "status",
  CREATED_AT: "created_at",
  MODIFIED_AT: "modified_at",
  CREATED_BY: "created_by",
  MODIFIED_By: "modified_by",
});

class User {
  _id;
  email;
  firstName = "";
  lastName = "";
  profilePic;
  contactNumber = "";
  address = "";
  domains = [];
  industry = "";
  sub_industry = "";
  resourceCostPerWord = "";
  gstVat = "";
  resume;
  sampleWork = [];
  linkedinUrl = "";
  website = "";
  additionalDetails = "";
  status = false;
  verified = false;
  profileApproved = false;
  createdAt = moment();
  modifiedAt = moment();
  createdBy;
  modifiedBy;

  fromMap = (map = {}) => {
    if (!map) return this;

    if (typeof map === "string") {
      this._id = map;
    } else {
      if (map[KEYS.ID]) this._id = map[KEYS.ID];
      if (map[KEYS.EMAIL]) this.email = map[KEYS.EMAIL];
      if (map[KEYS.FIRST_NAME]) this.firstName = map[KEYS.FIRST_NAME];
      if (map[KEYS.LAST_NAME]) this.lastName = map[KEYS.LAST_NAME];
      if (map[KEYS.CONTACT_NUMBER])
        this.contactNumber = map[KEYS.CONTACT_NUMBER];
      if (map[KEYS.ADDRESS]) this.address = map[KEYS.ADDRESS];
      if (map[KEYS.INDUSTRY]) this.industry = map[KEYS.INDUSTRY];
      if (map[KEYS.SUB_INDUSTRY]) this.sub_industry = map[KEYS.SUB_INDUSTRY];
      if (map[KEYS.DOMAIN]) this.domains = getStringToOptions(map[KEYS.DOMAIN]);
      if (map[KEYS.RESOURCE_COST_PER_WORD])
        this.resourceCostPerWord = map[KEYS.RESOURCE_COST_PER_WORD];
      if (map[KEYS.GST_VAT]) this.gstVat = map[KEYS.GST_VAT];
      if (map[KEYS.RESUME_URL])
        this.resume = new Attachment().fromMap(map[KEYS.RESUME_URL]);

      const sampleWork = map[KEYS.SAMPLE_WORK];
      this.sampleWork = [];
      if (Array.isArray(sampleWork)) {
        sampleWork.forEach((sw) => {
          const attachment = new Attachment().fromMap(sw);
          if (attachment._id) {
            this.sampleWork.push(attachment);
          }
        });
      }
      if (map[KEYS.LINKEDIN_URL]) this.linkedinUrl = map[KEYS.LINKEDIN_URL];
      if (map[KEYS.WEBSITE]) this.website = map[KEYS.WEBSITE];
      if (map[KEYS.ADDITIONAL_DETAILS])
        this.additionalDetails = map[KEYS.ADDITIONAL_DETAILS];

      if (map[KEYS.STATUS]) this.status = map[KEYS.STATUS];
      if (map[KEYS.VERIFIED]) this.verified = map[KEYS.VERIFIED];
      if (map[KEYS.CREATED_AT]) this.createdAt = map[KEYS.CREATED_AT];
      if (map[KEYS.MODIFIED_AT]) this.modifiedAt = map[KEYS.MODIFIED_AT];
    }

    return this;
  };

  get fullName() {
    return `${this.firstName} ${this.lastName}`.trim();
  }
}

export default User;
